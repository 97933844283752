<template>
  <div class="app-container">
    <el-row class="margin-bottom-20">
      <el-col :lg="16" :xs="16" :offset="0">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="Correo, Logro" v-model="searchForm.query"
          v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button @click.stop.prevent="handleSearchFilter" class="margin-left-10" size="mini" type="primary"
          icon="el-icon-search">Buscar</el-button>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button class="margin-left-10" @click.stop.prevent="handleClean" size="mini" type="warning"
          icon="el-icon-delete">Limpiar</el-button>
      </el-col>
    </el-row>
    <!---- Paginacion -->
    <el-row type="flex" justify="end">
      <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
        :total="parseInt(totalPages)">
      </el-pagination>
    </el-row>
    <!---- Tabla de Logros ----->
    <el-table size="mini" v-loading="loading" :data="list" element-loading-text="Loading" height="600" fit border
      highlight-current-row style="width: 100%">
      <el-table-column label="ID" fixed width="140" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{ scope.row._id !== undefined ? scope.row._id : 'No disponible' }}</div>
            <span>{{ scope.row._id !== undefined ? scope.row._id.toString().substr(-6) : 'N/D' }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="ID Usuario" width="150" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{ scope.row.userId !== undefined ? scope.row.userId : 'No disponible' }}</div>
            <span>{{ scope.row.userId !== undefined ? scope.row.userId.toString().substr(-6) : 'N/D' }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Nombre" width="250" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.userName !==undefined ? scope.row.userName : 'N/D'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Correo" width="220" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.userEmail !==undefined ? scope.row.userEmail : 'N/D'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Logro" align="center" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.achievementTitle !== undefined ? scope.row.achievementTitle : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Clave Logro" align="center" width="160">
        <template slot-scope="scope">
          <span>{{ scope.row.achievementKeyName !== undefined ? scope.row.achievementKeyName : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Fecha Logro" width="180" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.created_at !==undefined ? scope.row.created_at : 'N/D' | formatDate}}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { search, getPage } from '@/api/userAchievements.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'AchievementsUser',
  data () {
    return {
      pathServer: urlServer,
      searchForm: {
        query: '',
        userId: null
      },
      filterDateRegisterRange: '',
      totalPages: '',
      currentPage: '',
      loading: false,
      sizePerPage: 25,
      locations: null,
      types: null,
      list: [
      ],
      imgList: []
    }
  },
  mounted () {
    var idTemp = this.$route.params._id
    if (idTemp) {
      this.searchForm.userId = idTemp
    }
    this.handleSearchFilter()
  },
  methods: {
    handleDownloadDocument (file) {
    },
    // --- data search ----
    async handleSearchFilter () {
      this.loading = true
      await search(this.searchForm)
        .then((response) => {
          console.log(response)
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPage(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleClean () {
      this.searchForm.query = ''
      this.handleSearchFilter()
    }
  }
}
</script>

<style lang="css">

</style>
