import request from '@/utils/request'
export function search (params) {
  return request({
    url: '/api-manager/user-achievement/search',
    method: 'post',
    params
  })
}
export function getPage (page, noitems, params) {
  return request({
    url: '/api-manager/user-achievement/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}
